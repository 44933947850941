// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "owl.carousel";
import "slick-carousel";

// custom

jQuery(document).ready(function () {

    /* ===== Burger--menu--open ======= */

    jQuery(".burger__menu").click(function () {
        jQuery("body").toggleClass("open");
    });
    jQuery(document).on("click", function () {
        jQuery("body").removeClass("open");
    });
    jQuery(".nav-navigation, .burger__menu").on("click", function (e) {
        e.stopPropagation();
    });
});

/* ===== window Height  =======*/

jQuery(window).on("load resize", function () {
    const windowHeight = jQuery(window).height();
    const headerHeight = jQuery(".site-header").outerHeight();
    const footerHeight = jQuery(".site-footer").outerHeight();
    const totalheight = windowHeight - (headerHeight + footerHeight);
    jQuery(".site-main").css({ "min-height": totalheight });
    jQuery(".windowHeight").css({ height: windowHeight - headerHeight });
});

